import { Grid, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PrimaryButton from 'components/button/button-primary';
import { ChangeEvent } from 'types/common-types';
import ValidationError from 'components/errors/validation-error';

interface ButtonAttachFileInterface {
  grid?: {
    xs: number;
    sm: number;
  };
  multiple?: boolean;
  fileInput?: React.RefObject<HTMLInputElement>;
  onChange?: (e: ChangeEvent) => void;
  icon?: boolean;
  error?: string[];
}

const ButtonAttachFile = ({
  multiple,
  fileInput,
  onChange,
  grid = { xs: 12, sm: 6 },
  icon = true,
  error = []
}: ButtonAttachFileInterface) => {
  return (
    <Grid item xs={grid.xs} sm={grid.sm}>
      {icon ? (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          sx={{ ml: '10px' }}
        >
          <input
            hidden
            type="file"
            id="file-upload"
            multiple={multiple}
            ref={fileInput}
            onChange={onChange}
          />
          <AttachFileIcon color="info" fontSize="large" />
        </IconButton>
      ) : (
        <>
          <PrimaryButton
            variant="contained"
            onClick={() => fileInput?.current?.click()}
            fullWidth
          >
            Select File
            <input
              type="file"
              id="file-upload-select"
              multiple={true}
              ref={fileInput}
              onChange={onChange}
              hidden
            />
          </PrimaryButton>
          {error.length > 0 && <ValidationError data={error} />}
        </>
      )}
    </Grid>
  );
};

export default ButtonAttachFile;
