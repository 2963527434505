import brokerageTransactionRoleStatusType from 'assets/constants/brokerage-transaction-role-status-type';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import emptyFunction from 'helpers/empty-function-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { ChangeEvent } from 'react';
import { BrokerageTransactionRoleEntity } from 'types/brokerage-transaction-role-types';
import { ErrorValidation } from 'types/error-types';
import brokerageTransactionRoleConstant from 'assets/constants/brokerage-transaction-roles';
import UnitRead from 'components/form/unit-read';

interface recordViewType {
  brokerageTransactionRole: BrokerageTransactionRoleEntity;
  validation?: ErrorValidation;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
}

const RecordView = ({
  brokerageTransactionRole,
  validation,
  onChange,
  readOnly = false
}: recordViewType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  return (
    <FormContainer>
      <UnitSelect
        name="name"
        label={fieldLabel.name}
        records={getObjectEntriesAsArray(brokerageTransactionRoleConstant)}
        value={brokerageTransactionRole.name ?? ''}
        onChange={handleChange}
        required
        error={valMessages['name'] ?? ''}
        readOnly={readOnly}
      />

      <UnitSelect
        name="status"
        label={fieldLabel.status}
        records={getObjectEntriesAsArray(brokerageTransactionRoleStatusType)}
        value={brokerageTransactionRole.status ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
        required
        error={valMessages['status'] ?? ''}
      />

      <UnitText
        label={fieldLabel.description}
        name="description"
        value={brokerageTransactionRole.description ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
        multiline={true}
        rows={4}
      />

      {readOnly && (
        <>
          <UnitRead
            label={fieldLabel.lastActivatedAt}
            value={brokerageTransactionRole.last_activated_at ?? ''}
          />
          <UnitRead
            label={fieldLabel.lastDeactivatedAt}
            value={brokerageTransactionRole.last_deactivated_at ?? ''}
          />
        </>
      )}
    </FormContainer>
  );
};

export default RecordView;
