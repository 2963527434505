import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse,
  DeleteResponse
} from 'types/api-response-types';
import eventBus from 'helpers/event-bus-helper';
import client from 'services/axios-client';
import { ObjectType } from 'types';
import { unAuthorizedMessage } from 'assets/constants/messages';
import { ErrorValidation } from 'types/error-types';
import fieldLabel from 'assets/constants/fieldLabel';
import sessionHelper from 'helpers/session-helper';
import { isEmpty } from 'helpers/misc-helper';

const NETWORK_ERROR_RESPONSE = {
  isSuccess: false,
  isError: true,
  isValidationError: false,
  data: undefined,
  errorMessage: { message: fieldLabel.youAreOffline },
  validationMessage: {}
};

const getNetworkError = (value: any) => {
  return { ...NETWORK_ERROR_RESPONSE, data: value };
};

const initialListResponse = {
  data: [],
  meta: {
    total: 0
  }
};

export const list = async <T>(url: string): Promise<GetListResponse<T>> => {
  let response: {
    data: T[];
    meta: { total: number };
  } = initialListResponse;

  let errorMessage = {};
  let isError = false;
  let isSuccess = true;

  if (!checkConnectivity()) {
    return getNetworkError(initialListResponse);
  }

  try {
    const result = await client.get(url);
    response = result.data;
  } catch (error: any) {
    errorMessage = error;
    isSuccess = false;
    isError = true;

    logout(error);

    if (checkUnAuthorized(error)) {
      errorMessage = unAuthorizedMessage;
    }
  }

  return {
    isSuccess,
    isError,
    data: response,
    errorMessage
  };
};

export const get = async <T>(url: string): Promise<GetResponse<T>> => {
  let data: T;
  let errorMessage = {};
  let isError = false;
  let isSuccess = true;
  let result: { data: { data: T } };

  if (!checkConnectivity()) {
    return getNetworkError({});
  }

  try {
    result = await client.get(url);

    sessionHelper.setRecord(result.data.data);
  } catch (error: any) {
    errorMessage = error;
    isSuccess = false;
    isError = true;

    logout(error);

    if (checkUnAuthorized(error)) {
      errorMessage = unAuthorizedMessage;
    }

    if (hasNotFoundValidationError(error)) {
      errorMessage = {
        ...error
      };
      result = error.response;
    }
  }

  return {
    isSuccess,
    isError,
    data: result!.data.data,
    errorMessage
  };
};

export const post = async <T, U = any>(
  url: string,
  payload: T | U
): Promise<CreateResponse<T>> => {
  let data: any = {};
  let errorMessage = {};
  let isError = false;
  let isSuccess = true;
  let isValidationError = false;
  let validationMessage: ErrorValidation = {};

  if (!checkConnectivity()) {
    return getNetworkError({});
  }

  let result: { data: { data: T } };
  try {
    result = await client.post(url, payload);
    data = result.data.data;
  } catch (error: any) {
    errorMessage = error;
    isSuccess = false;
    isError = true;

    if (isUseCredentialError(error)) {
      errorMessage = error.response.data.message;
    }

    logout(error);

    if (hasValidationError(error)) {
      validationMessage = validationService(error?.response?.data?.errors);
      isValidationError = true;
    }
  }

  return {
    isSuccess,
    isError,
    isValidationError,
    data,
    errorMessage,
    validationMessage
  };
};

export const put = async <T, U = any>(
  url: string,
  payload: T | U
): Promise<UpdateResponse<T>> => {
  let data: any = {};
  let errorMessage = {};
  let isError = false;
  let isSuccess = true;
  let isValidationError = false;
  let validationMessage: ErrorValidation = {};

  if (!checkConnectivity()) {
    return getNetworkError({});
  }

  let result: { data: { data: T } };
  try {
    result = await client.put(url, payload);
    data = result.data.data;
  } catch (error: any) {
    errorMessage = error;
    isSuccess = false;
    isError = true;

    if (isUseCredentialError(error)) {
      errorMessage = error.response.data.message;
    }

    logout(error);

    if (hasValidationError(error)) {
      validationMessage = validationService(error?.response?.data?.errors);
      isValidationError = true;
    }
  }

  return {
    isSuccess,
    isError,
    isValidationError,
    data,
    errorMessage,
    validationMessage
  };
};

export const del = async <T>(url: string): Promise<DeleteResponse<T>> => {
  let result: { data: T };
  let errorMessage: any = '';
  let isError: boolean = false;
  let isSuccess: boolean = true;
  let isValidationError: boolean = false;
  let data: any = {};

  if (!checkConnectivity()) {
    return getNetworkError({});
  }

  try {
    result = await client.delete(url);
    data = result.data;
  } catch (error: any) {
    errorMessage = error.message || error;
    isSuccess = false;
    isError = true;

    logout(error);

    if (hasValidationError(error)) {
      errorMessage = validationService(error?.response?.data?.errors);
      isValidationError = true;
    }

    if (hasDependencyError(error)) {
      errorMessage = error.response.data.data.message
        ? error.response.data.data
        : error?.response?.data?.errors[0]?.message;
    }

    if (hasNotFoundValidationError(error)) {
      errorMessage = !isEmpty(error?.response?.data?.errors)
        ? error?.response?.data?.errors[0]
        : error?.response?.data?.data;
    }
  }

  return {
    isSuccess,
    isError,
    isValidationError,
    data,
    errorMessage
  };
};

const hasDependencyError = (error: ObjectType) => {
  return error.response && error.response.status === 424;
};

const isUseCredentialError = (error: any) => {
  return (
    error.response &&
    error.response.status === 400 &&
    error.response.data &&
    error.response.data?.error === 'invalid_grant'
  );
};

const isTokenExpiredError = (error: ObjectType) => {
  return error.response && error.response.status === 401;
};

export const hasValidationError = (error: ObjectType) => {
  return error.response && error.response.status === 422;
};

const hasNotFoundValidationError = (error: ObjectType) => {
  return error.response && error.response.status === 404;
};

const validationService = (errors: []) => {
  type resultType = {
    [key: string]: [];
  };

  const result: resultType = {};

  for (let i = 0; i < errors.length; i++) {
    const { field, message } = errors[i];

    if (typeof result[field] === 'undefined') {
      result[field] = [];
    }
    result[field].push(message);
  }

  return result;
};

const logout = (error: ObjectType) => {
  if (isTokenExpiredError(error)) {
    eventBus.dispatch('LOGOUT', {});
  }
};

const checkConnectivity = () => navigator.onLine;

const checkUnAuthorized = (error: ObjectType) => {
  return error.response.status === 403;
};

export default { list, get, post, put, del };
