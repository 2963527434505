import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import UnitText from 'components/form/unit-text';
import emptyFunction from 'helpers/empty-function-helper';
import { ErrorValidation } from 'types/error-types';
import UnitDate from 'components/form/unit-date';
import { FederalHolidayCalendarEntity } from 'types/federal-holiday-calendar';
import { InputChangeEvent } from 'types/common-types';

interface recordViewType {
  federalHolidayCalendar: FederalHolidayCalendarEntity;
  validation?: ErrorValidation;
  onChange?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
}

const RecordView = ({
  federalHolidayCalendar,
  validation,
  onChange,
  readOnly = false
}: recordViewType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  return (
    <FormContainer>
      <UnitText
        name="name"
        label={fieldLabel.name}
        value={federalHolidayCalendar.name ?? ''}
        onChange={handleChange}
        required
        error={valMessages['name'] ?? ''}
        readOnly={readOnly}
      />

      <UnitDate
        label={fieldLabel.holidayDate}
        name="holiday_date"
        value={federalHolidayCalendar.holiday_date ?? ''}
        onChange={(e: string) =>
          handleChange({
            target: {
              name: 'holiday_date',
              value: e
            }
          })
        }
        required
        error={valMessages['holiday_date'] ?? ''}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.description}
        name="description"
        value={federalHolidayCalendar.description ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
        multiline={true}
        rows={4}
      />
    </FormContainer>
  );
};

export default RecordView;
