import { MenuItem } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import fieldLabel from 'assets/constants/fieldLabel';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteLinkBlack from 'components/link/route-link-black';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import DateUtility from 'helpers/date-helper';
import { FederalHolidayCalendarListEntity } from 'types/federal-holiday-calendar';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import DropdownMenu from 'components/dropdown-menu';
import { deleteFederalHolidayCalendar } from 'helpers/federal-holiday-helper';
import { toastError, toastSuccess } from 'event/toast-event';

const Action = ({
  federalHolidayCalendar
}: {
  federalHolidayCalendar: FederalHolidayCalendarListEntity;
}) => {
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const ddMenuItems: JSX.Element[] = [
    <>
      <MenuItem
        key={uuid()}
        onClick={() => {
          setConfirmModal({
            open: true,
            title: fieldLabel.deleteFederalHolidayCalendar,
            text: MessageDeleteRecordConfirmation(
              federalHolidayCalendar.name,
              'Federal Holiday Calendar',
              true
            ),
            proceed: async () => {
              const result = await deleteFederalHolidayCalendar(
                federalHolidayCalendar.id!
              );
              setConfirmModal(initialConfirmModalState);
              if (result.isError) {
                toastError(result.errorMessage.message);
                return;
              }
              if (result.isSuccess) {
                toastSuccess(fieldLabel.federalHolidayCalendarDeleteSuccess);
              }
            },
            cancel: () => {
              setConfirmModal(initialConfirmModalState);
            }
          });
        }}
      >
        <RouteLinkBlack
          name={fieldLabel.deleteFederalHolidayCalendar}
          url={`#`}
        />
      </MenuItem>
      {confirmModal.open && <ConfirmBox key={uuid()} {...confirmModal} />}
    </>
  ];

  return (
    <>
      {<DropdownMenu menuItems={ddMenuItems} />}
      <RouteEditIconLink
        url={`/federal-holiday-calendars/${federalHolidayCalendar.id}/edit`}
      />
      <RouteOpenNewIconLink
        url={`/federal-holiday-calendars/${federalHolidayCalendar.id}/view`}
      />
    </>
  );
};

const federalHolidayCalendarColumns: GridColDef[] = [
  {
    headerName: 'Action',
    field: 'action',
    sortable: false,
    renderCell: (
      param: GridRenderCellParams<FederalHolidayCalendarListEntity>
    ) => <Action federalHolidayCalendar={param.row} />,
    flex: 0.6
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1,
    renderCell: (
      param: GridRenderCellParams<FederalHolidayCalendarListEntity>
    ) => (
      <RouteLink
        url={`/federal-holiday-calendars/${param.row.id}/view`}
        name={param.row.name}
      />
    )
  },
  {
    headerName: fieldLabel.description,
    field: 'description',
    sortable: false,
    flex: 1
  },
  {
    headerName: fieldLabel.holidayDate,
    field: 'holiday_date',
    sortable: true,
    flex: 0.5,
    valueGetter: (
      params: GridValueGetterParams<FederalHolidayCalendarListEntity>
    ) => {
      return DateUtility.getDateString(params.row.holiday_date);
    }
  },
  {
    headerName: fieldLabel.dateEntered,
    field: 'date_entered',
    sortable: true,
    flex: 0.5,
    valueGetter: (
      params: GridValueGetterParams<FederalHolidayCalendarListEntity>
    ) => {
      return DateUtility.getDateTimeString(params.row.date_entered);
    }
  }
];

export default federalHolidayCalendarColumns;
