import BrokerageTransactionRoleViewChangelog from 'features/brokerage-transaction-roles/brokerage-transaction-role-change-log';
import BrokerageTransactionRoleCreate from 'features/brokerage-transaction-roles/brokerage-transaction-role-create';
import BrokerageTransactionRolesList from 'features/brokerage-transaction-roles/brokerage-transaction-role-list';
import BrokerageTransactionRoleView from 'features/brokerage-transaction-roles/brokerage-transaction-role-view';
import BrokerageTransactionRolePage from 'pages/brokerage-transaction-role';

const brokerageTransactionRoleRoutes = {
  path: 'brokerage-transaction-roles',
  element: <BrokerageTransactionRolePage />,
  children: [
    {
      path: '',
      element: (
        <BrokerageTransactionRolesList routeTag="brokerage-transaction-roles" />
      )
    },
    {
      path: 'create',
      element: (
        <BrokerageTransactionRoleCreate routeTag="brokerage-transaction-roles-create" />
      )
    },
    {
      path: ':brokerage_transaction_role_id/edit',
      element: (
        <BrokerageTransactionRoleCreate routeTag="brokerage-transaction-roles-edit" />
      )
    },
    {
      path: ':brokerage_transaction_role_id/view',
      element: (
        <BrokerageTransactionRoleView routeTag="brokerage-transaction-roles-view" />
      )
    },
    {
      path: ':brokerage_transaction_role_id/change-log',
      element: (
        <BrokerageTransactionRoleViewChangelog routeTag="brokerage-transaction-roles-change-log" />
      )
    }
  ]
};

export default brokerageTransactionRoleRoutes;
