import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'federal-holiday-calendars') {
      return await menuHelper.getItems(
        appModel.federalHolidayCalendar,
        appPermission.list
      );
    }

    if (routeName === 'federal-holiday-calendars-view') {
      return await menuHelper.getItems(
        appModel.federalHolidayCalendar,
        appPermission.view
      );
    }

    if (routeName === 'federal-holiday-calendars-create') {
      return await menuHelper.getItems(
        appModel.federalHolidayCalendar,
        appPermission.list
      );
    }

    if (routeName === 'federal-holiday-calendars-edit') {
      return await menuHelper.getItems(
        appModel.federalHolidayCalendar,
        appPermission.edit
      );
    }

    return [];
  }
};

export default sideManuHelper;
