import { ErrorValidation } from 'types/error-types';
import { isEmpty } from 'helpers/misc-helper';
import { missingField } from 'assets/validation-template';
import { ObjectType } from 'types';

export const validateFederalHolidayCalendar = (
  data: ObjectType
): { status: boolean; errors: ErrorValidation } => {
  let validation: { status: boolean; errors: ErrorValidation } = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        name: [missingField('Name')]
      }
    };
  }

  if (isEmpty(data.holiday_date)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        holiday_date: [missingField('Holiday Date')]
      }
    };
  }

  return validation;
};
