const initialAccountBuyerNameListFilter = {
  name: '',
  account_id: '',
  account_name: '',
  market_id: '',
  market_name: '',
  entera_customer_buyer_name_id: ''
};

export default initialAccountBuyerNameListFilter;
