const initialPdfTemplate = {
  id: '',
  name: '',
  template_order_c: '',
  state_c: '',
  document_state: '',
  category_id: '',
  document_subtype: '',
  document_tag: '',
  type: '',
  active: 0,
  expire_version: 0,
  margin_left: '15',
  margin_right: '15',
  margin_top: '16',
  margin_bottom: '16',
  margin_header: '9',
  margin_footer: '9',
  description: '',
  pdffooter: '',
  pdfheader: '',
  homeowner_association: '',
  offer_package: '',
  contract_type: '',
  inspection: '',
  date_modified: '',
  market_preference_pdf_template_count: 0,
  contract_revision: {
    id: '',
    pdf_template_id: '',
    docgen_template_id: '',
    filename: '',
    file_ext: '',
    revision: ''
  }
};

export default initialPdfTemplate;
