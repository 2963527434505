import FormContainer from 'components/form/container';
import UnitSelect from 'components/form/unit-select';
import UnitSwitch from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import { ObjectType } from 'types';
import fieldLabel from 'assets/constants/fieldLabel';
import { PdfTemplateEntity } from 'types/pdf-template-types';
import { useParams } from 'react-router-dom';
import { OptionType } from 'types/option-type';
import UnitHtmlEditor from 'components/form/unit-html-editor';
import emptyFunction from 'helpers/empty-function-helper';
import contentType from 'assets/constants/content-type';
import offerPackage from 'assets/constants/offer-package';
import homeOwnerAssociation from 'assets/constants/home-owner-association';
import documentType from 'assets/constants/document-type';
import documentState from 'assets/constants/document-state';
import state from 'assets/constants/state';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import type from 'assets/constants/type';
import { Dispatch, SetStateAction, useRef } from 'react';
import { ContentType } from './pdf-template-create';
import { ErrorValidation } from 'types/error-types';
import { ChangeEvent, InputChangeEvent } from 'types/common-types';
import UnitDateTime from 'components/form/unit-date-time';
import UnitEmpty from 'components/form/unit-empty';
import documentTags from 'assets/constants/document-tags';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import UnitItem from 'components/form/unit-item';
import ButtonAttachFile from 'components/form/button-attach-file';
import FieldLabel from 'components/form/field-label';
import { toastError } from 'event/toast-event';
import RouteLink from 'components/link/route-link';
import UnitRead from 'components/form/unit-read';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { isEmpty } from 'helpers/misc-helper';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import contractRevisionService from 'services/contract-revision-service';
import fileDownloadHelper from 'helpers/file-download-helper';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

interface recordViewType {
  pdfTemplate: PdfTemplateEntity;
  validation?: ErrorValidation;
  onChange?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
  theme?: string;
  contentBody?: string;
  contentHeader?: string;
  contentFooter?: string;
  setContentBody?: React.Dispatch<React.SetStateAction<ContentType>>;
  setContentHeader?: React.Dispatch<React.SetStateAction<ContentType>>;
  setContentFooter?: React.Dispatch<React.SetStateAction<ContentType>>;
  subTypeOption?: OptionType[];
  setPdfTemplate?: Dispatch<SetStateAction<PdfTemplateEntity>>;
  selectedFile?: File;
  setSelectedFile?: Dispatch<File | undefined>;
}

const RecordView = ({
  pdfTemplate,
  validation,
  onChange,
  readOnly = false,
  theme = 'snow',
  contentBody = '',
  contentHeader = '',
  contentFooter = '',
  setContentBody,
  setContentHeader,
  setContentFooter,
  subTypeOption,
  setPdfTemplate,
  setSelectedFile,
  selectedFile
}: recordViewType): JSX.Element => {
  const { market_preference_id } = useParams<{
    market_preference_id: string;
  }>();

  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  let fileInput = useRef<HTMLInputElement>(null);

  const contractRevisionId = pdfTemplate.contract_revision?.id;

  const handleDownloadClick = async () => {
    const result = await contractRevisionService.getPresignUrl(
      contractRevisionId
    );
    if (result.isSuccess) {
      fileDownloadHelper(result.data);
    }
  };

  return (
    <FormContainer>
      {!market_preference_id && (
        <>
          <UnitText
            label={fieldLabel.name}
            name="name"
            value={pdfTemplate.name ?? ''}
            onChange={handleChange}
            error={valMessages['name'] ?? ''}
            required
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.templateOrder}
            name="template_order_c"
            value={pdfTemplate.template_order_c ?? ''}
            onChange={handleChange}
            error={valMessages['template_order_c'] ?? ''}
            readOnly={readOnly}
          />

          <UnitSelect
            name="state_c"
            label={fieldLabel.state}
            records={getObjectEntriesAsArray(state)}
            value={pdfTemplate.state_c ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
            error={valMessages['state_c'] ?? ''}
          />

          <UnitSelect
            name="document_state"
            label={fieldLabel.documentState}
            records={getObjectEntriesAsArray(documentState)}
            value={pdfTemplate.document_state ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
          />

          <UnitSelect
            name="category_id"
            label={fieldLabel.documentType}
            records={getObjectEntriesAsArray(documentType)}
            value={pdfTemplate?.category_id ?? ''}
            onChange={(e: ObjectType) => {
              if (setPdfTemplate !== undefined) {
                setPdfTemplate((prevPdfTemplate: PdfTemplateEntity) => ({
                  ...prevPdfTemplate,
                  category_id: e.target.value,
                  document_subtype: ''
                }));
              }
            }}
            readOnly={readOnly}
            error={valMessages['category_id'] ?? ''}
          />

          {pdfTemplate?.category_id === 'misc' ? (
            <UnitText
              label={fieldLabel.documentSubType}
              name="document_subtype"
              value={pdfTemplate?.document_subtype ?? ''}
              onChange={handleChange}
              readOnly={readOnly}
              error={valMessages['document_subtype'] ?? ''}
            />
          ) : (
            <UnitSelect
              name="document_subtype"
              label={fieldLabel.documentSubType}
              records={subTypeOption}
              value={pdfTemplate?.document_subtype || ''}
              onChange={handleChange}
              readOnly={readOnly}
              error={valMessages['document_subtype'] ?? ''}
            />
          )}

          {pdfTemplate.category_id === 'purchase_contract' && (
            <UnitSelect
              name="document_tag"
              label={fieldLabel.documentTag}
              records={getObjectEntriesAsArray(documentTags)}
              value={pdfTemplate.document_tag ?? ''}
              onChange={handleChange}
              error={valMessages['document_tag'] ?? ''}
              required
              readOnly={readOnly}
            />
          )}

          <UnitSelect
            name="type"
            label={fieldLabel.type}
            records={getObjectEntriesAsArray(type)}
            value={pdfTemplate.type ?? ''}
            onChange={handleChange}
            error={valMessages['type'] ?? ''}
            required
            readOnly={readOnly}
          />

          <UnitSwitch
            value={pdfTemplate.expire_version ?? 0}
            onChange={handleChange}
            name="expire_version"
            label={fieldLabel.expire}
            disabled={readOnly}
          />

          <UnitText
            label={fieldLabel.docgenTemplateId}
            name="docgen_template_id"
            value={pdfTemplate?.contract_revision?.docgen_template_id ?? ''}
            onChange={handleChange}
            readOnly={true}
          />

          {readOnly === true && !isEmpty(contractRevisionId) ? (
            <UnitRead
              label={fieldLabel.fileName}
              value={
                <>
                  <RouteLink
                    url={`/pdf-templates/${pdfTemplate.id}/contract_revisions/${pdfTemplate.contract_revision?.id}/viewer`}
                    name={
                      <IconButton>
                        <VisibilityIcon />
                      </IconButton>
                    }
                    target={true}
                  />

                  <IconButton onClick={handleDownloadClick}>
                    <FileDownloadIcon />
                  </IconButton>

                  <RouteLink
                    url={`/pdf-templates/${pdfTemplate.id}/contract_revisions/${pdfTemplate.contract_revision?.id}/viewer`}
                    name={`${pdfTemplate.contract_revision?.filename}`}
                    target={true}
                  />
                </>
              }
            />
          ) : (
            <UnitItem>
              <FieldLabel>Contract File</FieldLabel>
              {!readOnly && (
                <ButtonAttachFile
                  fileInput={fileInput}
                  onChange={(e: ChangeEvent) => {
                    const files = fileInput.current?.files;
                    if (
                      files &&
                      files[0] &&
                      files[0].type === 'application/pdf'
                    ) {
                      setSelectedFile?.(files[0]);
                    } else {
                      toastError('Please select a PDF file.');
                    }
                    e.target.value = '';
                  }}
                  icon={false}
                  grid={{ sm: 12, xs: 12 }}
                  error={valMessages['file'] ?? ''}
                />
              )}
              {selectedFile ? (
                <Typography variant="body2" marginTop={1}>
                  Selected file: {selectedFile.name}
                </Typography>
              ) : (
                pdfTemplate.contract_revision?.filename && (
                  <Typography variant="body2" marginTop={1}>
                    Selected file: {pdfTemplate.contract_revision.filename}
                  </Typography>
                )
              )}
            </UnitItem>
          )}

          {readOnly && (
            <>
              <UnitDateTime
                label={fieldLabel.dateModified}
                name="date_modified"
                value={pdfTemplate.date_modified}
                onChange={emptyFunction}
                readOnly={readOnly}
              />
              <UnitEmpty />
            </>
          )}

          <UnitText
            label={fieldLabel.marginLeft}
            name="margin_left"
            value={pdfTemplate.margin_left ?? ''}
            onChange={handleChange}
            error={valMessages['margin_left'] ?? ''}
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.marginRight}
            name="margin_right"
            value={pdfTemplate.margin_right ?? ''}
            onChange={handleChange}
            error={valMessages['margin_right'] ?? ''}
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.marginTop}
            name="margin_top"
            value={pdfTemplate.margin_top ?? ''}
            onChange={handleChange}
            error={valMessages['margin_top'] ?? ''}
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.marginBottom}
            name="margin_bottom"
            value={pdfTemplate.margin_bottom ?? ''}
            onChange={handleChange}
            error={valMessages['margin_bottom'] ?? ''}
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.marginHeader}
            name="margin_header"
            value={pdfTemplate.margin_header ?? ''}
            onChange={handleChange}
            error={valMessages['margin_header'] ?? ''}
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.marginFooter}
            name="margin_footer"
            value={pdfTemplate.margin_footer ?? ''}
            onChange={handleChange}
            error={valMessages['margin_footer'] ?? ''}
            readOnly={readOnly}
          />

          <UnitHtmlEditor
            grid={{ xs: 12, sm: 12 }}
            value={readOnly ? pdfTemplate.description : contentBody}
            readOnly={readOnly}
            theme={theme}
            onChange={(pdfTemplate: ContentType) => {
              if (setContentBody !== undefined) setContentBody(pdfTemplate);
            }}
            label="Body"
          />

          <UnitHtmlEditor
            grid={{ xs: 12, sm: 12 }}
            value={readOnly ? pdfTemplate.pdfheader : contentHeader}
            readOnly={readOnly}
            theme={theme}
            onChange={(pdfTemplate: ContentType) => {
              if (setContentHeader !== undefined) setContentHeader(pdfTemplate);
            }}
            label="Header"
          />

          <UnitHtmlEditor
            grid={{ xs: 12, sm: 12 }}
            value={readOnly ? pdfTemplate.pdffooter : contentFooter}
            readOnly={readOnly}
            theme={theme}
            onChange={(pdfTemplate: ContentType) => {
              if (setContentFooter !== undefined) setContentFooter(pdfTemplate);
            }}
            label="Footer"
          />
        </>
      )}

      {market_preference_id && (
        <>
          <UnitSelect
            name="homeowner_association"
            label={fieldLabel.homeOwnerAssociation}
            records={getObjectEntriesAsArray(homeOwnerAssociation)}
            value={pdfTemplate.homeowner_association ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
          />

          <UnitSelect
            name="offer_package"
            label={fieldLabel.offerPackage}
            records={getObjectEntriesAsArray(offerPackage)}
            value={pdfTemplate.offer_package ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
          />

          <UnitSelect
            name="contract_type"
            label={fieldLabel.type}
            records={getObjectEntriesAsArray(contentType)}
            value={pdfTemplate.contract_type ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
          />
        </>
      )}
    </FormContainer>
  );
};

export default RecordView;
