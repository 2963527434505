import fieldLabel from 'assets/constants/fieldLabel';
import userStatus from 'assets/constants/user-status';
import ClearButton from 'components/form/button-clear';
import SearchButton from 'components/form/button-search';
import FormContainer from 'components/form/container';
import UnitSelect from 'components/form/unit-select';
import TextUnit from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRow from 'components/stack/stack-row';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import initialUserListFilter from 'state/user/initial-users-list-filter';
import { ChangeEvent, DispatchSetState } from 'types/common-types';
import { UserFilterEntity } from 'types/user';

interface FilterProps {
  filter: UserFilterEntity;
  updateFilter: (val: ChangeEvent) => void;
  setIsFilterChanged: DispatchSetState<boolean>;
  setFilter: (val: UserFilterEntity) => void;
}

const Filters = (props: FilterProps): JSX.Element => {
  const { filter, updateFilter, setIsFilterChanged, setFilter } = props;

  return (
    <PaperBox>
      <PaperBoxContent>
        <FormContainer>
          <TextUnit
            label={fieldLabel.firstName}
            name="first_name"
            value={filter.first_name}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 2.4 }}
          />

          <TextUnit
            label={fieldLabel.lastName}
            name="last_name"
            value={filter.last_name}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 2.4 }}
          />

          <TextUnit
            label={fieldLabel.userName}
            name="user_name"
            value={filter.user_name}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 2.4 }}
          />

          <TextUnit
            label={fieldLabel.email}
            name="email"
            value={filter.email}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 2.4 }}
          />

          <UnitSelect
            name="status"
            label={fieldLabel.status}
            records={getObjectEntriesAsArray(userStatus)}
            value={filter.status ?? ''}
            onChange={updateFilter}
            readOnly={false}
            grid={{ xs: 12, sm: 2.4 }}
          />

          <StackRow>
            <SearchButton
              onClick={() => {
                setIsFilterChanged(true);
              }}
            />
            <ClearButton
              onClick={() => {
                setFilter(initialUserListFilter);
                setIsFilterChanged(true);
              }}
            />
          </StackRow>
        </FormContainer>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default Filters;
