import federalHolidayCalendarService from 'services/federal-holiday-calendar-service';
import DateUtility from './date-helper';
import { getItem, setItem } from './local-storage-helper';
import listQueryString from './query-string-helper';
import eventBus from './event-bus-helper';

const federalHolidayHelper = {
  loadFederalHolidays: async () => {
    const query = listQueryString({
      pagination: {
        page: 0,
        pageSize: 300
      },
      sort: {
        field: 'date_entered',
        sort: 'asc'
      },
      filter: {}
    });
    const result = await federalHolidayCalendarService.getList(query);
    const federalHolidays = result.data.data.map((x) => x.holiday_date);
    setItem('federal_holidays', JSON.stringify(federalHolidays));
  },

  rolloverDateIfFallsOnFederalHoliday: (
    targetDate: string,
    checkForWeekend: boolean
  ) => {
    let validDate = targetDate;
    let isTargetDateFallOnFederalHoliday = true;
    let invalidDates = [];

    do {
      if (federalHolidayHelper.isDateFallonFederalHoliday(targetDate)) {
        invalidDates.push(DateUtility.getDateString(targetDate));
      }
      if (
        federalHolidayHelper.isDateFallonFederalHoliday(targetDate) ||
        (checkForWeekend && DateUtility.isWeekend(targetDate))
      ) {
        validDate = DateUtility.getCalendarWorkingDay(
          validDate,
          '1'
        ).toString();
        targetDate = validDate;
      } else {
        isTargetDateFallOnFederalHoliday = false;
      }
    } while (isTargetDateFallOnFederalHoliday === true);

    return { validDate, invalidDate: invalidDates.join(',') };
  },

  isDateFallonFederalHoliday: (targetDate: string) => {
    const federalHolidays = JSON.parse(getItem('federal_holidays') || '[]');

    return federalHolidays.includes(
      DateUtility.getFormattedDateString(targetDate)
    );
  },

  getFederalHolidayValidationMessage: (
    targetDate: string,
    ValidDate: string,
    field: string
  ): string => {
    return `The date you have chosen ${targetDate} is a federal holiday, so the ${field} will be recorded as ${ValidDate}`;
  },

  rolloverDateIfFallsOnFederalHolidayAndGetMessage: (
    date: string,
    field: string,
    checkForWeekend: boolean
  ): {
    validDate: string;
    message: string[];
  } => {
    const targetDate = DateUtility.getFormattedDateString(date);

    const { validDate, invalidDate } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHoliday(
        targetDate,
        checkForWeekend
      );

    let validation: string[] = [];

    if (federalHolidayHelper.isDateFallonFederalHoliday(targetDate)) {
      validation = [
        federalHolidayHelper.getFederalHolidayValidationMessage(
          invalidDate,
          DateUtility.getDateString(validDate),
          field
        )
      ];
    }

    return {
      validDate,
      message: validation
    };
  }
};

export default federalHolidayHelper;

const deleteFederalHolidayCalendar = async (
  federal_holiday_calendar_id: string
) => {
  const result = await federalHolidayCalendarService.delete(
    federal_holiday_calendar_id
  );

  if (result.isSuccess)
    eventBus.dispatch(`federal_holiday_calendar_refresh`, {});

  return result;
};

export { deleteFederalHolidayCalendar };
