import { useNavigate, useParams } from 'react-router-dom';
import fieldLabel from 'assets/constants/fieldLabel';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import { useState } from 'react';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import sessionHelper from 'helpers/session-helper';
import { toastError, toastSuccess } from 'event/toast-event';
import { deleteFederalHolidayCalendar } from 'helpers/federal-holiday-helper';

const FederalHolidayCalendarDelete = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { federal_holiday_calendar_id } = useParams<{
    federal_holiday_calendar_id: string;
  }>();

  const federalHolidayCalendar = sessionHelper.getRecord(
    federal_holiday_calendar_id
  );
  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    open: true,
    title: fieldLabel.deleteFederalHolidayCalendar,
    text: MessageDeleteRecordConfirmation(
      federalHolidayCalendar.name,
      'Federal Holiday Calendar',
      true
    ),
    proceed: async () => {
      const result = await deleteFederalHolidayCalendar(
        federalHolidayCalendar.id
      );

      if (result.isError) {
        toastError(result.errorMessage.message);
        window.history.back();
      }

      if (result.isSuccess) {
        toastSuccess(fieldLabel.federalHolidayCalendarDeleteSuccess);
        navigate(`/federal-holiday-calendars`);
      }
    },
    cancel: () => {
      setConfirmModal(initialConfirmModalState);
      window.history.back();
    }
  });

  return <>{confirmModal.open && <ConfirmBox {...confirmModal} />}</>;
};
export default FederalHolidayCalendarDelete;
