import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RecordView from '../record-view';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';
import { FederalHolidayCalendarEntity } from 'types/federal-holiday-calendar';
import initialFederalHolidayCalendar from 'state/federal-holiday-calendar/initial-federal-holiday-calendar';
import federalHolidayCalendarService from 'services/federal-holiday-calendar-service';

const FederalHolidayCalendarView = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { federal_holiday_calendar_id } = useParams<{
    federal_holiday_calendar_id: string;
  }>();
  const [federalHolidayCalendar, setFederalHolidayCalendar] =
    useState<FederalHolidayCalendarEntity>(initialFederalHolidayCalendar);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadFederalHolidayCalendar = async (
    federal_holiday_calendar_id: string
  ) => {
    const response = await federalHolidayCalendarService.get(
      federal_holiday_calendar_id
    );

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setFederalHolidayCalendar(response.data);
  };

  useEffect(() => {
    if (federal_holiday_calendar_id)
      loadFederalHolidayCalendar(federal_holiday_calendar_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView
            federalHolidayCalendar={federalHolidayCalendar}
            readOnly={true}
          />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default FederalHolidayCalendarView;
