import AccountBuyerNameCreate from 'features/account-buyer-names/account-buyer-name-create';
import AccountBuyerNamesList from 'features/account-buyer-names/account-buyer-name-list';
import AccountBuyerNameView from 'features/account-buyer-names/account-buyer-name-view';
import AccountBuyerNamePage from 'pages/account-buyer-name';

const accountBuyerNameRoutes = {
  path: 'account-buyer-names',
  element: <AccountBuyerNamePage />,
  children: [
    {
      path: '',
      element: <AccountBuyerNamesList routeTag="account-buyer-names" />
    },
    {
      path: 'create',
      element: <AccountBuyerNameCreate routeTag="account-buyer-names-create" />
    },
    {
      path: ':account_buyer_name_id/edit',
      element: <AccountBuyerNameCreate routeTag="account-buyer-names-edit" />
    },
    {
      path: ':account_buyer_name_id/view',
      element: <AccountBuyerNameView routeTag="account-buyer-names-view" />
    }
  ]
};

export default accountBuyerNameRoutes;
