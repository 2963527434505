import {
  PdfTemplateDeleteResponse,
  PdfTemplateEntity,
  PdfTemplateListEntity,
  PdfTemplatePayloadEntity
} from 'types/pdf-template-types';
import app from 'config/app';
import httpService from './http-service';
import { GetListResponse, GetResponse } from 'types/api-response-types';
import {
  ContractRevisionEntity,
  ContractRevisionPresignUrlEntity
} from 'types/contract-revision-types';

const contractRevisionService = {
  url: `/${app.crmApiVersion1}/contract_revision`,

  getList: async (
    query: string
  ): Promise<GetListResponse<ContractRevisionEntity>> => {
    const result = await httpService.list<ContractRevisionEntity>(
      `${contractRevisionService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<ContractRevisionEntity>> => {
    const result = await httpService.get<ContractRevisionEntity>(
      `${contractRevisionService.url}/${id}`
    );
    return result;
  },

  getPresignUrl: async (
    id: string
  ): Promise<GetResponse<ContractRevisionPresignUrlEntity>> => {
    const response = await httpService.get<ContractRevisionPresignUrlEntity>(
      `${contractRevisionService.url}/${id}/presigned_url`
    );
    return response;
  }
};

export default contractRevisionService;
