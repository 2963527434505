import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import accountBuyerNameStatus from 'assets/constants/account-buyer-name-status';
import fieldLabel from 'assets/constants/fieldLabel';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import DateUtility from 'helpers/date-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { AccountBuyerNameListEntity } from 'types/account-buyer-name-types';

const Action = ({
  accountBuyerName
}: {
  accountBuyerName: AccountBuyerNameListEntity;
}) => {
  return (
    <>
      <RouteEditIconLink
        url={`/account-buyer-names/${accountBuyerName.id}/edit`}
      />
      <RouteOpenNewIconLink
        url={`/account-buyer-names/${accountBuyerName.id}/view`}
      />
    </>
  );
};

const accountBuyerNameColumns: GridColDef[] = [
  {
    headerName: fieldLabel.action,
    field: 'action',
    sortable: false,
    renderCell: (param: GridRenderCellParams<AccountBuyerNameListEntity>) => (
      <Action accountBuyerName={param.row} />
    ),
    flex: 0.6
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1,
    renderCell: (param: GridRenderCellParams<AccountBuyerNameListEntity>) => (
      <RouteLink
        url={`/account-buyer-names/${param.row.id}/view`}
        name={param.row.name}
      />
    )
  },
  {
    headerName: fieldLabel.account,
    field: 'account',
    sortable: false,
    flex: 0.5,
    renderCell: (param: GridRenderCellParams<AccountBuyerNameListEntity>) => (
      <RouteLink
        url={`/accounts/${param.row.account?.id}/view`}
        name={param.row.account?.name ?? ''}
      />
    )
  },
  {
    headerName: fieldLabel.enteraCustomerId,
    field: 'entera_customer_id',
    sortable: false,
    flex: 0.5
  },
  {
    headerName: fieldLabel.market,
    field: 'market',
    sortable: false,
    flex: 0.5,
    renderCell: (param: GridRenderCellParams<AccountBuyerNameListEntity>) => (
      <RouteLink
        url={`/msa/${param.row.market?.id}/view`}
        name={param.row.market?.name ?? ''}
      />
    )
  },
  {
    headerName: fieldLabel.enteraMarketId,
    field: 'entera_market_id',
    sortable: false,
    flex: 0.5
  },
  {
    headerName: fieldLabel.enteraCustomerBuyerNameId,
    field: 'entera_customer_buyer_name_id',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.enteraStatus,
    field: 'entera_status',
    sortable: true,
    flex: 0.5,
    valueGetter: (param: GridValueGetterParams<AccountBuyerNameListEntity>) => {
      return getObjectKeyValue(accountBuyerNameStatus, param.row.entera_status);
    }
  },
  {
    headerName: fieldLabel.dateEntered,
    field: 'date_entered',
    sortable: true,
    flex: 0.5,
    valueGetter: (param: GridValueGetterParams<AccountBuyerNameListEntity>) => {
      return DateUtility.getDateTimeString(param.row.date_entered);
    }
  }
];

export default accountBuyerNameColumns;
