import FederalHolidayCalendarCreate from 'features/federal-holiday-calendars/federal-holiday-calendar-create';
import FederalHolidayCalendarDelete from 'features/federal-holiday-calendars/federal-holiday-calendar-delete';
import FederalHolidayCalendarsList from 'features/federal-holiday-calendars/federal-holiday-calendar-list';
import FederalHolidayCalendarView from 'features/federal-holiday-calendars/federal-holiday-calendar-view';
import FederalHolidayCalendarPage from 'pages/federal-holiday-calendar';

const federalHolidayCalendarRoutes = {
  path: 'federal-holiday-calendars',
  element: <FederalHolidayCalendarPage />,
  children: [
    {
      path: '',
      element: (
        <FederalHolidayCalendarsList routeTag="federal-holiday-calendars" />
      )
    },
    {
      path: 'create',
      element: (
        <FederalHolidayCalendarCreate routeTag="federal-holiday-calendars-create" />
      )
    },
    {
      path: ':federal_holiday_calendar_id/edit',
      element: (
        <FederalHolidayCalendarCreate routeTag="federal-holiday-calendars-edit" />
      )
    },
    {
      path: ':federal_holiday_calendar_id/view',
      element: (
        <FederalHolidayCalendarView routeTag="federal-holiday-calendars-view" />
      )
    },
    {
      path: ':federal_holiday_calendar_id/delete',
      element: (
        <FederalHolidayCalendarDelete routeTag="federal-holiday-calendars-delete" />
      )
    }
  ]
};

export default federalHolidayCalendarRoutes;
