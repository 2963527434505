import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import UnitText from 'components/form/unit-text';
import emptyFunction from 'helpers/empty-function-helper';
import { ErrorValidation } from 'types/error-types';
import { ChangeEvent } from 'types/common-types';
import { AccountBuyerNameEntity } from 'types/account-buyer-name-types';
import UnitAccount from 'components/form/unit-account';
import UnitMsa from 'components/form/unit-msa';
import { OptionType } from 'types/option-type';
import { ObjectType } from 'types';
import UnitSelect from 'components/form/unit-select';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import accountBuyerNameStatus from 'assets/constants/account-buyer-name-status';
import UnitRead from 'components/form/unit-read';
import RouteLink from 'components/link/route-link';

interface recordViewType {
  accountBuyerName: AccountBuyerNameEntity;
  validation?: ErrorValidation;
  onChange?: (e: ChangeEvent | ObjectType) => void;
  readOnly?: boolean;
}

const RecordView = ({
  accountBuyerName,
  validation,
  onChange,
  readOnly = false
}: recordViewType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  return (
    <FormContainer>
      <UnitText
        name="name"
        label={fieldLabel.name}
        value={accountBuyerName.name ?? ''}
        onChange={handleChange}
        required
        error={valMessages['name']}
        readOnly={readOnly}
      />
      <UnitText
        name="entera_customer_buyer_name_id"
        label={fieldLabel.enteraCustomerBuyerNameId}
        value={accountBuyerName.entera_customer_buyer_name_id ?? ''}
        onChange={handleChange}
        error={valMessages['entera_customer_buyer_name_id']}
        readOnly={readOnly}
      />
      {readOnly === true ? (
        <UnitRead
          label={fieldLabel.account}
          value={
            <>
              <RouteLink
                url={`${`/accounts/${accountBuyerName.account_id}/view`}`}
                name={accountBuyerName.account_name || '-'}
                target={true}
              />
            </>
          }
        />
      ) : (
        <UnitAccount
          value={{
            label: accountBuyerName.account_name ?? '',
            value: accountBuyerName.account_id
          }}
          multiple={false}
          onChange={(val: OptionType) => {
            handleChange({
              account_id: val?.value ?? '',
              account_name: val?.label ?? ''
            });
          }}
          error={valMessages['account_id']}
          readOnly={readOnly}
          required
        />
      )}

      <UnitRead
        label={fieldLabel.enteraCustomerId}
        value={accountBuyerName.entera_customer_id}
      />

      {readOnly === true ? (
        <UnitRead
          label={fieldLabel.msa}
          value={
            <>
              <RouteLink
                url={`${`/msa/${accountBuyerName.market_id}/view`}`}
                name={accountBuyerName.market_name || '-'}
                target={true}
              />
            </>
          }
        />
      ) : (
        <UnitMsa
          value={{
            value: accountBuyerName.market_id,
            label: accountBuyerName.market_name ?? ''
          }}
          multiple={false}
          onChange={(val: OptionType) => {
            handleChange({
              market_id: val?.value ?? '',
              market_name: val?.label ?? ''
            });
          }}
          error={valMessages['market_id']}
          readOnly={readOnly}
          required
        />
      )}

      <UnitRead
        label={fieldLabel.enteraMarketId}
        value={accountBuyerName.entera_market_id}
      />

      <UnitSelect
        name="entera_status"
        label={fieldLabel.enteraStatus}
        records={getObjectEntriesAsArray(accountBuyerNameStatus)}
        value={accountBuyerName.entera_status ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
        error={valMessages['entera_status']}
        required
      />
    </FormContainer>
  );
};

export default RecordView;
