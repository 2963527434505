import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RecordView from '../record-view';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';
import { BrokerageTransactionRoleEntity } from 'types/brokerage-transaction-role-types';
import initialBrokerageTransactionRole from 'state/brokerage-transaction-role/initial-brokerage-transaction-role';
import brokerageTransactionRoleService from 'services/brokerage-transaction-role-service';

const BrokerageTransactionRoleView = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { brokerage_transaction_role_id } = useParams<{
    brokerage_transaction_role_id: string;
  }>();
  const [brokerageTransactionRole, setBrokerageTransactionRole] =
    useState<BrokerageTransactionRoleEntity>(initialBrokerageTransactionRole);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadBrokerageTransactionRole = async (
    brokerage_transaction_role_id: string
  ) => {
    const response = await brokerageTransactionRoleService.get(
      brokerage_transaction_role_id
    );

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setBrokerageTransactionRole(response.data);
  };

  useEffect(() => {
    if (brokerage_transaction_role_id)
      loadBrokerageTransactionRole(brokerage_transaction_role_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView
            brokerageTransactionRole={brokerageTransactionRole}
            readOnly={true}
          />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default BrokerageTransactionRoleView;
