import React, { useEffect, useState } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import brokerageTransactionRoleColumns from 'assets/list/brokerage-transaction-role/column';
import defaultSort from 'assets/list/party/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import { GetListResponse } from 'types/api-response-types';
import brokerageTransactionRoleService from 'services/brokerage-transaction-role-service';
import { BrokerageTransactionRoleListEntity } from 'types/brokerage-transaction-role-types';

const BrokerageTransactionRolesList = ({ routeTag }: { routeTag: string }) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getBrokerageTransactionRoles = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<BrokerageTransactionRoleListEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: {}
    });

    setIsLoading(true);

    const result = await brokerageTransactionRoleService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <DataGrid<BrokerageTransactionRoleListEntity>
        getData={getBrokerageTransactionRoles}
        tableColumns={brokerageTransactionRoleColumns}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </>
  );
};

export default BrokerageTransactionRolesList;
