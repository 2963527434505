const initialFederalHolidayCalendar = {
  id: '',
  name: '',
  date_entered: '',
  date_modified: '',
  modified_user_id: '',
  created_by: '',
  description: '',
  deleted: '',
  assigned_user_id: '',
  holiday_date: ''
};

export default initialFederalHolidayCalendar;
