import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import brokerageTransactionRoleStatusType from 'assets/constants/brokerage-transaction-role-status-type';
import fieldLabel from 'assets/constants/fieldLabel';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import DateUtility from 'helpers/date-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { BrokerageTransactionRoleListEntity } from 'types/brokerage-transaction-role-types';

const Action = ({
  brokerageTransactionRole
}: {
  brokerageTransactionRole: BrokerageTransactionRoleListEntity;
}) => {
  return (
    <>
      <RouteEditIconLink
        url={`/brokerage-transaction-roles/${brokerageTransactionRole.id}/edit`}
      />
      <RouteOpenNewIconLink
        url={`/brokerage-transaction-roles/${brokerageTransactionRole.id}/view`}
      />
    </>
  );
};

const brokerageTransactionRoleColumns: GridColDef[] = [
  {
    headerName: 'Action',
    field: 'action',
    sortable: false,
    renderCell: (
      param: GridRenderCellParams<BrokerageTransactionRoleListEntity>
    ) => <Action brokerageTransactionRole={param.row} />,
    flex: 0.6
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: false,
    flex: 1,
    renderCell: (
      param: GridRenderCellParams<BrokerageTransactionRoleListEntity>
    ) => (
      <RouteLink
        url={`/brokerage-transaction-roles/${param.row.id}/view`}
        name={param.row.name}
      />
    )
  },
  {
    headerName: fieldLabel.description,
    field: 'description',
    sortable: false,
    flex: 1
  },
  {
    headerName: fieldLabel.status,
    field: 'status',
    sortable: false,
    flex: 0.5,
    valueGetter: (
      param: GridValueGetterParams<BrokerageTransactionRoleListEntity>
    ) => {
      return getObjectKeyValue(
        brokerageTransactionRoleStatusType,
        param.row.status
      );
    }
  },
  {
    headerName: fieldLabel.lastActivatedAt,
    field: 'last_activated_at',
    sortable: false,
    flex: 0.5,
    valueGetter: (
      params: GridValueGetterParams<BrokerageTransactionRoleListEntity>
    ) => {
      return DateUtility.getDateTimeString(params.row.last_activated_at);
    }
  },
  {
    headerName: fieldLabel.lastDeactivatedAt,
    field: 'last_deactivated_at',
    sortable: false,
    flex: 0.5,
    valueGetter: (
      params: GridValueGetterParams<BrokerageTransactionRoleListEntity>
    ) => {
      return DateUtility.getDateTimeString(params.row.last_deactivated_at);
    }
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    sortable: false,
    flex: 0.5,
    valueGetter: (
      params: GridValueGetterParams<BrokerageTransactionRoleListEntity>
    ) => {
      return DateUtility.getDateTimeString(params.row.date_modified);
    }
  },
  {
    headerName: fieldLabel.dateEntered,
    field: 'date_entered',
    sortable: false,
    flex: 0.5,
    valueGetter: (
      params: GridValueGetterParams<BrokerageTransactionRoleListEntity>
    ) => {
      return DateUtility.getDateTimeString(params.row.date_entered);
    }
  }
];

export default brokerageTransactionRoleColumns;
