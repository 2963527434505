import fieldLabel from 'assets/constants/fieldLabel';
import ClearButton from 'components/form/button-clear';
import SearchButton from 'components/form/button-search';
import FormContainer from 'components/form/container';
import UnitAccount from 'components/form/unit-account';
import UnitItem from 'components/form/unit-item';
import UnitMsa from 'components/form/unit-msa';
import TextUnit from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRow from 'components/stack/stack-row';
import initialAccountBuyerNameListFilter from 'state/account-buyer-name/initial-account-buyer-name-list-filter';
import { ObjectType } from 'types';
import { AccountBuyerNameFilterEntity } from 'types/account-buyer-name-types';
import { ChangeEvent, DispatchSetState } from 'types/common-types';
import { OptionType } from 'types/option-type';

interface FilterProps {
  filter: AccountBuyerNameFilterEntity;
  updateFilter: (val: ChangeEvent | ObjectType) => void;
  setIsFilterChanged: DispatchSetState<boolean>;
  setFilter: (val: AccountBuyerNameFilterEntity) => void;
}

const Filters = (props: FilterProps): JSX.Element => {
  const { filter, updateFilter, setIsFilterChanged, setFilter } = props;

  return (
    <PaperBox>
      <PaperBoxContent>
        <FormContainer>
          <TextUnit
            label={fieldLabel.name}
            name="name"
            value={filter.name}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 3 }}
          />

          <UnitAccount
            value={{
              label: filter.account_name ?? '',
              value: filter.account_id ?? ''
            }}
            multiple={false}
            onChange={(val: OptionType) => {
              updateFilter({
                account_id: val?.value ?? '',
                account_name: val?.label ?? ''
              });
            }}
            grid={{ xs: 12, sm: 3 }}
          />

          <UnitMsa
            value={{
              label: filter.market_name ?? '',
              value: filter.market_id ?? ''
            }}
            multiple={false}
            onChange={(val: OptionType) => {
              updateFilter({
                market_id: val?.value ?? '',
                market_name: val?.label ?? ''
              });
            }}
            grid={{ xs: 12, sm: 3 }}
          />

          <TextUnit
            label={fieldLabel.enteraCustomerBuyerNameId}
            name="entera_customer_buyer_name_id"
            value={filter.entera_customer_buyer_name_id}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 3 }}
          />

          <UnitItem grid={{ xs: 12, sm: 6, md: 4 }}>
            <StackRow isUnitItem>
              <SearchButton
                onClick={() => {
                  setIsFilterChanged(true);
                }}
              />
              <ClearButton
                onClick={() => {
                  setFilter(initialAccountBuyerNameListFilter);
                  setIsFilterChanged(true);
                }}
              />
            </StackRow>
          </UnitItem>
        </FormContainer>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default Filters;
