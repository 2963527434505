const initialDefaultValues = {
  id: '',
  name: '',
  category: '',
  subcategory_1: '',
  subcategory_2: '',
  assigned_user: '',
  description: '',
  value: ''
};

export default initialDefaultValues;
