import { RouteObject } from 'react-router-dom';

import AdminPage from 'pages/admin';
import RoleList from 'features/roles/roles-list';
import UsersList from 'features/users/users-list';
import FeaturedFlagList from 'features/featured-flag';
import DefaultValuesList from 'features/default-values/default-values-list';

const adminRoutes: RouteObject = {
  path: 'admin',
  element: <AdminPage />,
  children: [
    { path: 'users', element: <UsersList /> },
    { path: 'roles', element: <RoleList /> },
    { path: 'feature_flag', element: <FeaturedFlagList /> },
    { path: 'default_values', element: <DefaultValuesList /> }
  ]
};

export default adminRoutes;
