import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RecordView from '../record-view';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';
import { AccountBuyerNameEntity } from 'types/account-buyer-name-types';
import initialAccountBuyerName from 'state/account-buyer-name/initial-account-buyer-name';
import accountBuyerNameService from 'services/account-buyer-name-service';

const AccountBuyerNameView = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { account_buyer_name_id } = useParams<{
    account_buyer_name_id: string;
  }>();
  const [accountBuyerName, setAccountBuyerName] =
    useState<AccountBuyerNameEntity>(initialAccountBuyerName);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadAccountBuyerName = async (account_buyer_name_id: string) => {
    const response = await accountBuyerNameService.get(account_buyer_name_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    const data = {
      ...response.data,
      market_name: !isEmpty(response.data.market)
        ? response.data.market.name
        : '',
      account_name: !isEmpty(response.data.account)
        ? response.data.account.name
        : ''
    };

    setAccountBuyerName(data);
  };

  useEffect(() => {
    if (account_buyer_name_id) loadAccountBuyerName(account_buyer_name_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView accountBuyerName={accountBuyerName} readOnly={true} />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default AccountBuyerNameView;
