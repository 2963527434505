import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/document-revision/default-sort';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';
import contractRevisionService from 'services/contract-revision-service';
import { ContractRevisionEntity } from 'types/contract-revision-types';
import pdfTemplateRevisionColumns from 'assets/list/pdf-template-revision/columns';

const ContractRevisions = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { setRouteName } = useRouteName();

  const { pdf_template_id } = useParams<{ pdf_template_id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const getRevisions = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<ContractRevisionEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: { pdf_template_id }
    });

    setIsLoading(true);
    const result = await contractRevisionService.getList(queryString);
    setIsLoading(false);

    return result;
  };

  useEffect(() => {
    setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <React.Fragment>
      <DataGrid<ContractRevisionEntity>
        getData={getRevisions}
        tableColumns={pdfTemplateRevisionColumns}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default ContractRevisions;
