import app from 'config/app';
import httpService from './http-service';
import {
  CreateResponse,
  DeleteResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import {
  FederalHolidayCalendarDeleteResponse,
  FederalHolidayCalendarEntity,
  FederalHolidayCalendarListEntity,
  FederalHolidayCalendarPayloadEntity
} from 'types/federal-holiday-calendar';

const federalHolidayCalendarService = {
  url: `/${app.crmApiVersion1}/federal_holiday_calendar`,

  getList: async (
    query: string
  ): Promise<GetListResponse<FederalHolidayCalendarListEntity>> => {
    const result = await httpService.list<FederalHolidayCalendarListEntity>(
      `${federalHolidayCalendarService.url}${query}`
    );

    return result;
  },

  get: async (
    id: string
  ): Promise<GetResponse<FederalHolidayCalendarEntity>> => {
    const response = await httpService.get<FederalHolidayCalendarEntity>(
      `${federalHolidayCalendarService.url}/${id}`
    );
    return response;
  },

  create: async (
    data: FederalHolidayCalendarPayloadEntity
  ): Promise<CreateResponse<FederalHolidayCalendarEntity>> => {
    const response = await httpService.post<
      FederalHolidayCalendarEntity,
      FederalHolidayCalendarPayloadEntity
    >(`${federalHolidayCalendarService.url}`, data);
    return response;
  },

  update: async (
    id: string,
    data: FederalHolidayCalendarPayloadEntity
  ): Promise<UpdateResponse<FederalHolidayCalendarEntity>> => {
    const response = await httpService.put<FederalHolidayCalendarEntity>(
      `${federalHolidayCalendarService.url}/${id}`,
      data
    );
    return response;
  },

  delete: async (
    id: string
  ): Promise<DeleteResponse<FederalHolidayCalendarDeleteResponse>> => {
    const result = await httpService.del<FederalHolidayCalendarDeleteResponse>(
      `${federalHolidayCalendarService.url}/${id}`
    );
    return result;
  }
};

export default federalHolidayCalendarService;
