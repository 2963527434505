const appModel = {
  service: 'SERVICE',
  opportunityBrokerageUser: 'OPPORTUNITY_BROKERAGE_USER',
  party: 'parties',
  task: 'Tasks',
  emailTemplate: 'Email_Template',
  call: 'Calls',
  sqs: 'SQS',
  AOSPdfTemplates: 'AOS_PDF_Templates',
  documents: 'Documents',
  email: 'Emails',
  admin: 'admin',
  brokerageTransactionRole: 'Brokerage_Transaction_Roles',
  federalHolidayCalendar: 'Federal_Holiday_Calendars',
  accountBuyerName: 'Account_Buyer_Name'
};

export default appModel;
