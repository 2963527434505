const initialAccountBuyerName = {
  id: '',
  name: '',
  date_entered: '',
  date_modified: '',
  modified_user_id: '',
  created_by: '',
  description: '',
  deleted: '',
  assigned_user_id: '',
  entera_status: '',
  account_id: '',
  account_name: '',
  entera_customer_id: '',
  market_id: '',
  market_name: '',
  entera_market_id: '',
  entera_customer_buyer_name_id: '',
  account: {
    id: '',
    name: ''
  },
  market: {
    id: '',
    name: ''
  }
};

export default initialAccountBuyerName;
