import {
  DefaultValuesFilterEntity,
  DefaultValuesListEntity
} from 'types/default-values-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/account/default-sort';
import defaultValuesService from 'services/default-values-sercvice';
import Filters from './filters';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';
import dfaultValuesColumns from 'assets/list/default-values/columns';
import ModalComponent from 'components/modal';
import DefaultValuesCreate from '../default-values-create';
import { isEmpty } from 'helpers/misc-helper';
import initialConfirmModal from 'state/confirm-box/initial-confirm-box';
import ConfirmBox, {
  ConfirmModalProps
} from 'components/confirm-box/confirm-box';
import fieldLabel from 'assets/constants/fieldLabel';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import { toastError, toastSuccess } from 'event/toast-event';

const DefaultValuesList = ({ routeTag }: { routeTag?: string }) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [activeRecord, setActiveRecord] = useState<string | null>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<DefaultValuesFilterEntity>({ name: '' });
  const [confirmModal, setConfirmModal] =
    useState<ConfirmModalProps>(initialConfirmModal);

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFilter((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const getDefaultValues = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<DefaultValuesListEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filter
    });

    setIsLoading(true);

    const result = await defaultValuesService.getList(queryString);

    setIsLoading(false);
    return result;
  };
  const onEdit = (id: string) => setActiveRecord(id);

  const onDelete = (data: DefaultValuesListEntity) => {
    setConfirmModal({
      open: true,
      title: `Remove ${fieldLabel.defaultValues}`,
      text: MessageDeleteRecordConfirmation(
        data.name,
        fieldLabel.defaultValues
      ),

      proceed: async () => {
        const result = await defaultValuesService.delete(data.id);

        if (result.isError) {
          toastError(result.errorMessage.message);
          return;
        }

        toastSuccess('Default Values removed successfully');
        setConfirmModal(initialConfirmModal);
        setIsFilterChanged(true);
      },
      cancel: () => {
        setConfirmModal(initialConfirmModal);
      }
    });
  };

  useEffect(() => {
    setRouteName(routeTag ?? '');
  }, []);

  return (
    <>
      <Filters
        filter={filter}
        updateFilter={updateFilter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
        isLoading={isLoading}
        addNew={() => {
          setActiveRecord(null);
        }}
      />

      <DataGrid<DefaultValuesListEntity>
        getData={getDefaultValues}
        tableColumns={dfaultValuesColumns({ onEdit, onDelete })}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />

      {activeRecord !== undefined && (
        <ModalComponent
          Component={DefaultValuesCreate}
          data={{
            routeTag: isEmpty(activeRecord) ? 'record-add' : 'record-edit',
            defaultValues_id: activeRecord,
            onCancel: (reloadUsers: boolean) => {
              setActiveRecord(undefined);
              if (reloadUsers) setIsFilterChanged(!isFilterChanged);
            }
          }}
          onClose={() => setActiveRecord(undefined)}
          loading={false}
          size="lg"
        />
      )}

      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </>
  );
};

export default DefaultValuesList;
