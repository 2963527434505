import app from 'config/app';
import httpService from './http-service';
import {
  CreateResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import {
  AccountBuyerNameEntity,
  AccountBuyerNameListEntity,
  AccountBuyerNamePayloadEntity
} from 'types/account-buyer-name-types';

const accountBuyerNameService = {
  url: `/${app.crmApiVersion1}/account_buyer_names`,

  getAccountBuyerNameByOpportunity: async (
    query: string
  ): Promise<GetListResponse<AccountBuyerNameListEntity>> => {
    const result = await httpService.list<AccountBuyerNameListEntity>(
      `${accountBuyerNameService.url}${query}`
    );
    return result;
  },

  getList: async (
    query: string
  ): Promise<GetListResponse<AccountBuyerNameListEntity>> => {
    const result = await httpService.list<AccountBuyerNameListEntity>(
      `${accountBuyerNameService.url}/list${query}`
    );
    return result;
  },

  get: async (id: string): Promise<GetResponse<AccountBuyerNameEntity>> => {
    const response = await httpService.get<AccountBuyerNameEntity>(
      `${accountBuyerNameService.url}/${id}`
    );
    return response;
  },

  create: async (
    data: AccountBuyerNamePayloadEntity
  ): Promise<CreateResponse<AccountBuyerNameEntity>> => {
    const response = await httpService.post<
      AccountBuyerNameEntity,
      AccountBuyerNamePayloadEntity
    >(`${accountBuyerNameService.url}`, data);
    return response;
  },

  update: async (
    id: string,
    data: AccountBuyerNamePayloadEntity
  ): Promise<UpdateResponse<AccountBuyerNameEntity>> => {
    const response = await httpService.put<AccountBuyerNameEntity>(
      `${accountBuyerNameService.url}/${id}`,
      data
    );
    return response;
  }
};

export default accountBuyerNameService;
