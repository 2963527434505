import { ErrorValidation } from 'types/error-types';
import { isEmpty, isNumber } from 'helpers/misc-helper';
import { missingField } from 'assets/validation-template';
import { ObjectType } from 'types';

export const validateAccountBuyerName = (
  data: ObjectType
): { status: boolean; errors: ErrorValidation } => {
  let validation: { status: boolean; errors: ErrorValidation } = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        name: [missingField('Name')]
      }
    };
  }

  if (isEmpty(data.market_id)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        market_id: [missingField('Market')]
      }
    };
  }

  if (isEmpty(data.account_id)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        account_id: [missingField('Account')]
      }
    };
  }

  if (
    !isEmpty(data.entera_customer_buyer_name_id) &&
    !isNumber(data.entera_customer_buyer_name_id)
  ) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        entera_customer_buyer_name_id: [
          'Invalid Value: Entera Customer Buyer Name ID'
        ]
      }
    };
  }

  if (isEmpty(data.entera_status)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        entera_status: [missingField('Status')]
      }
    };
  }

  return validation;
};
