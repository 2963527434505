const initialBrokerageTransactionRole = {
  id: '',
  name: '',
  date_entered: '',
  date_modified: '',
  description: '',
  status: '',
  last_activated_at: '',
  last_deactivated_at: ''
};

export default initialBrokerageTransactionRole;
