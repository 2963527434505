import envConfig from 'config/env';
import {
  CreateResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import httpService from './http-service';
import {
  BrokerageTransactionRoleEntity,
  BrokerageTransactionRoleListEntity,
  BrokerageTransactionRolePayloadEntity
} from 'types/brokerage-transaction-role-types';

const brokerageTransactionRoleService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/brokerage_transaction_roles`,

  getList: async (
    query: string
  ): Promise<GetListResponse<BrokerageTransactionRoleListEntity>> => {
    const result = await httpService.list<BrokerageTransactionRoleListEntity>(
      `${brokerageTransactionRoleService.url}${query}`
    );

    return result;
  },

  get: async (
    id: string
  ): Promise<GetResponse<BrokerageTransactionRoleEntity>> => {
    const response = await httpService.get<BrokerageTransactionRoleEntity>(
      `${brokerageTransactionRoleService.url}/${id}`
    );
    return response;
  },

  create: async (
    data: BrokerageTransactionRolePayloadEntity
  ): Promise<CreateResponse<BrokerageTransactionRoleEntity>> => {
    const response = await httpService.post<
      BrokerageTransactionRoleEntity,
      BrokerageTransactionRolePayloadEntity
    >(`${brokerageTransactionRoleService.url}`, data);
    return response;
  },

  update: async (
    id: string,
    data: BrokerageTransactionRolePayloadEntity
  ): Promise<UpdateResponse<BrokerageTransactionRoleEntity>> => {
    const response = await httpService.put<BrokerageTransactionRoleEntity>(
      `${brokerageTransactionRoleService.url}/${id}`,
      data
    );
    return response;
  },

  getTransactionRolesByName: async (
    search: string
  ): Promise<GetListResponse<BrokerageTransactionRoleListEntity>> => {
    const result = await httpService.list<BrokerageTransactionRoleListEntity>(
      `${brokerageTransactionRoleService.url}?sort[field]=date_entered&sort[direction]=asc&filter[name]=${search}&filter[status]=active`
    );

    return result;
  }
};

export default brokerageTransactionRoleService;
