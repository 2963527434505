import React, { useEffect, useState } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import defaultSort from 'assets/list/party/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import { GetListResponse } from 'types/api-response-types';
import {
  FederalHolidayCalendarFilterEntity,
  FederalHolidayCalendarListEntity
} from 'types/federal-holiday-calendar';
import federalHolidayCalendarColumns from 'assets/list/federal-holiday-calendar/column';
import federalHolidayCalendarService from 'services/federal-holiday-calendar-service';
import initialFederalHolidayCalendarListFilter from 'state/federal-holiday-calendar/initial-federal-holiday-calendar-list-filter';
import { ChangeEvent } from 'types/common-types';
import Filters from './filters';

const FederalHolidayCalendarsList = ({ routeTag }: { routeTag: string }) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<FederalHolidayCalendarFilterEntity>(
    initialFederalHolidayCalendarListFilter
  );

  const updateFilter: (e: ChangeEvent) => void = (e: ChangeEvent) => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const getFederalHolidayCalendars = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<FederalHolidayCalendarListEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filter
    });

    setIsLoading(true);

    const result = await federalHolidayCalendarService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <Filters
        filter={filter}
        updateFilter={updateFilter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
      />

      <DataGrid<FederalHolidayCalendarListEntity>
        getData={getFederalHolidayCalendars}
        tableColumns={federalHolidayCalendarColumns}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        setIsFilterChanged={setIsFilterChanged}
        isFilterChanged={isFilterChanged}
        refreshEvent="federal_holiday_calendar"
      />
    </>
  );
};

export default FederalHolidayCalendarsList;
