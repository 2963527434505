import app from 'config/app';
import httpService from './http-service';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse,
  DeleteResponse
} from 'types/api-response-types';
import {
  DefaultValuesDeleteEntity,
  DefaultValuesEntity,
  DefaultValuesListEntity,
  DefaultValuesPayloadEntity
} from 'types/default-values-types';

const defaultValuesService = {
  url: `/${app.crmApiVersion1}/default_values`,

  getList: async (
    query: string
  ): Promise<GetListResponse<DefaultValuesListEntity>> => {
    const result = await httpService.list<DefaultValuesListEntity>(
      `${defaultValuesService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<DefaultValuesEntity>> => {
    const result = await httpService.get<DefaultValuesEntity>(
      `${defaultValuesService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: DefaultValuesPayloadEntity
  ): Promise<CreateResponse<DefaultValuesEntity>> => {
    const result = await httpService.post<DefaultValuesEntity>(
      `${defaultValuesService.url}`,
      payload
    );
    return result;
  },

  update: async (
    id: string,
    payload: DefaultValuesPayloadEntity
  ): Promise<UpdateResponse<DefaultValuesEntity>> => {
    const result = await httpService.put<
      DefaultValuesEntity,
      DefaultValuesPayloadEntity
    >(`${defaultValuesService.url}/${id}`, payload);
    return result;
  },
  delete: async (
    id: string
  ): Promise<DeleteResponse<DefaultValuesDeleteEntity>> => {
    const result = await httpService.del<DefaultValuesDeleteEntity>(
      `${defaultValuesService.url}/${id}`
    );
    return result;
  }
};

export default defaultValuesService;
