import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'brokerage-transaction-roles') {
      return await menuHelper.getItems(
        appModel.brokerageTransactionRole,
        appPermission.list
      );
    }

    if (routeName === 'brokerage-transaction-roles-view') {
      return await menuHelper.getItems(
        appModel.brokerageTransactionRole,
        appPermission.view
      );
    }

    if (routeName === 'brokerage-transaction-roles-create') {
      return await menuHelper.getItems(
        appModel.brokerageTransactionRole,
        appPermission.list
      );
    }

    if (routeName === 'brokerage-transaction-roles-edit') {
      return await menuHelper.getItems(
        appModel.brokerageTransactionRole,
        appPermission.edit
      );
    }

    if (routeName === 'brokerage-transaction-roles-change-log') {
      return await menuHelper.getItems(
        appModel.brokerageTransactionRole,
        appPermission.view
      );
    }
    return [];
  }
};

export default sideManuHelper;
